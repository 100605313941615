import React from "react";
import PropTypes from "prop-types";

import "./photo.css";

/**
 * NAME: Rob Worron
 * DATE: August 20, 2024
 */

/**
 * This Photo component can be used across the website to redefine its size based on one of three sizes;
 * small, medium, and large. For its initial deployment, it is used in the newsfeed to modify the size of
 * the primary picture for a news story.
 */

// The UI for a Photo component
export const Photo = ({ name, size }) => {
  const image = require(`../../assets/${name}`);

  return (
    <div className="photo">
      <img src={image} className={`photo--${size}`}></img>;
    </div>
  );
};

// Props for the photo
Photo.propTypes = {
  // What is the name of the asset to be retrieved?
  name: PropTypes.string.isRequired,

  // What is the size of the Photo?
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

// Default props for a Photo
Photo.defaultProps = {
  name: "logos/logo-transparent-white.png",
  size: "medium",
};
