import React from "react";
import { Rules } from "../../components/Rules/Rules";

import "./rulespage.css";
import "../webpage.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * The RulesPage is a page for visitors to the website to get a view of the rules of the league.
 * Like all other pages, it uses a Header, NavBar, SideBar, ad Footer to build out the core look
 * of the webpage. It includes a hyperlink that visitors can click on to download an attachment
 * of the rules for their own use. It uses the stylings from webpage.css and rulespage.css to
 * style its content.
 */

// The UI of the RulesPage
export const RulesPage = () => (
  <div className="rulespage">
    <main>
      <div className="rulespage--content">
        <h1>CMHL League Rules</h1>
        <Rules />
      </div>
    </main>
  </div>
);
