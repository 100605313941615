import React from "react";
import PropTypes from "prop-types";
import { Logo } from "../Logo/Logo";

import "./standings.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * The Standings component is a table styled to display the league standings. Similar to the Schedule
 * component, the table component takes in a {header} and a {body} as the table data, and a {colour}
 * to indicate what the colour of the table is going to be.
 */

// The UI for the Standings component
export const Standings = ({ primary, header, body, colour }) => {
  const mode = primary ? "standings--primary" : "standings--secondary";

  const getTeamLogoFileName = (teamName) => {
    const modifiedTeamName = teamName.replace(/\s/g, "").toLowerCase();
    return modifiedTeamName + "-transparent";
  };

  return (
    <table className={["standings", `standings--${colour}`, mode].join(" ")}>
      <thead className={`${mode}-header`}>
        <tr>
          <th className="standings--rank-cell">{header[0]}</th>
          <th className="standings--teamheader-cell">{header[1]}</th>
          {header.slice(2).map((headerData, index) => (
            <th key={index}>{headerData} </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td className={`${mode}-rank-cell`}>{row.Rank}</td>
            <td className={`${mode}-team-cell`}>
              {primary && (
                <Logo
                  size={"medium"}
                  padding={10}
                  name={getTeamLogoFileName(row.Team)}
                />
              )}
              {row.Team}
            </td>
            {header.slice(2).map((headerData, colIndex) => (
              <td key={colIndex + 2} className={`${mode}-stat-cell`}>
                {row[headerData]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// Props for the standings
Standings.propTypes = {
  // Is this the primary standings?
  primary: PropTypes.bool,

  // What is the header data for the standings?
  header: PropTypes.arrayOf(PropTypes.string).isRequired,

  // What is the body data for the standings?
  body: PropTypes.arrayOf(PropTypes.object).isRequired,

  // What is the colour scheme of the standings?
  colour: PropTypes.oneOf(["black", "red", "grey"]),
};

// Default props of the Standings component
Standings.defaultProps = {
  primary: false,
  header: [
    "Rank",
    "Team",
    "W",
    "L",
    "T",
    "Points",
    "GF",
    "GA",
    "GD",
    "L5",
    "Streak",
  ],
  body: [
    {
      Rank: 1,
      Team: "Axemen",
      W: 15,
      L: 3,
      T: 4,
      Points: 34,
      GF: 111,
      GA: 75,
      GD: 36,
      L5: "1-2-2",
      Streak: "W1",
    },
    {
      Rank: 2,
      Team: "Gulls",
      W: 10,
      L: 10,
      T: 2,
      Points: 22,
      GF: 91,
      GA: 95,
      GD: -4,
      L5: "3-1-1",
      Streak: "L1",
    },
    {
      Rank: 3,
      Team: "Rockies",
      W: 7,
      L: 11,
      T: 4,
      Points: 18,
      GF: 79,
      GA: 98,
      GD: -19,
      L5: "2-1-2",
      Streak: "W2",
    },
    {
      Rank: 4,
      Team: "Whiskey Dekes",
      W: 5,
      L: 13,
      T: 4,
      Points: 14,
      GF: 78,
      GA: 91,
      GD: -13,
      L5: "1-3-1",
      Streak: "L2",
    },
  ],
  colour: "black",
};
