import React from "react";
import PropTypes from "prop-types";

import "./button.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This Button component will be used in theNavBar to access other pages throughout the website.
 * The user determines whether the button is either of primary or secondary design via the {primary}
 * boolean. The user can change the {backgroundColour} and the {size} of the button. Additionally,
 * the user passes a {label} for the text to be displayed inside the button. Lastly, when pressed,
 * the {onClick} functionality defines what the button does or where it goes when clicked.
 *
 * I've used the template designed and given by Storybook when setting up my environment. I made
 * minor changes to props including size and colour to accomodate the design of the website.
 */

// The UI for a Button component
export const Button = ({ primary, backgroundColour, size, label }) => {
  const mode = primary ? "button--primary" : "button--secondary";

  return (
    <button
      type="button"
      className={["button", `button--${size}`, mode].join(" ")}
      style={backgroundColour && { backgroundColour }}
    >
      {label}
    </button>
  );
};

// Props for the button
Button.propTypes = {
  // Is this a primary or secondary button?
  primary: PropTypes.bool,

  // What is the colour of the button?
  backgroundColour: PropTypes.string,

  // What is the size of the button?
  size: PropTypes.oneOf(["small", "medium", "large"]),

  // What is the content of the button?
  label: PropTypes.string.isRequired,
};

// Default props for the button
Button.defaultProps = {
  backgroundColour: null,
  primary: false,
  size: "medium",
};
