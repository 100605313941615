import React from "react";
import { Schedule } from "../../components/Schedule/Schedule";

import "./scorespage.css";
import "../webpage.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * The ScoresPage is the page where visitors can view all prior and future games and scores for the league.
 * Like all other pages, it uses a Header, NavBar, SideBar, and Footer to give a streamlined look to the
 * website. It also uses a Schedule component in the main part of the page that is the key element to the
 * page. It uses the stylings from webpage.css and scorespage.css to style its content.
 */

// The UI of the ScoresPage
export const ScoresPage = () => (
  <div className="scorespage">
    <main>
      <div className="scorespage--body">
        <h1>2023 CMHL Scores and Schedule</h1>
        <Schedule />
      </div>
    </main>
  </div>
);

export default ScoresPage;
