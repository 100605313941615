import React from "react";
import { Link } from "react-router-dom";
import { MailIcon } from "@heroicons/react/outline";
import { Button } from "../Button/Button";
import { Logo } from "../Logo/Logo";

import "./navbar.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This NavBar component is used on every page and immediately follows the header component. It allows
 * users to navigate to different pages across the website. It contains the wordmark of the league
 * and multiple buttons for navigation. A 'spacer' is implemented to give the logo and its buttons
 * some space between each of them. Lastly, an icon and a contact email is included for visitors to
 * easily see how to get in contact with the league commissioner.
 *
 * In a future update, I intend on having the NavBar be 'sticky' to the top of the screen in the sense
 * that as a user scrolls down the page, the NavBar sticks to the top of the page for user convenience.
 */

// The UI for a NavBar component
export const NavBar = () => {
  return (
    <>
      <div className="navbar--black-line" />
      <div className="navbar--white-line" />
      <nav className="navbar">
        <span className="navbar--spacer" />
        <Logo name="wordmark-transparent" size={"medium"} />
        <span className="navbar--spacer" />
        <Link to="/" className="navbar--link">
          <Button label="Home" primary />
        </Link>
        <span className="navbar--spacer" />
        <Link to="/scores" className="navbar--link">
          <Button label="Schedule" primary />
        </Link>
        <span className="navbar--spacer" />
        <Link to="/standings" className="navbar--link">
          <Button label="Standings" primary />
        </Link>
        <span className="navbar--spacer" />
        <Link to="/info" className="navbar--link">
          <Button label="Info" primary />
        </Link>
        <span className="navbar--spacer" />
        <Link to="/rules" className="navbar--link">
          <Button label="Rules" primary />
        </Link>
        <span className="navbar--spacer" />
        <div className="navbar--contact">
          <MailIcon style={{ width: 24, padding: 10 }} />
          <p>cmhlniagara@gmail.com</p>
        </div>
      </nav>
      <div className="navbar--white-line" />
      <div className="navbar--black-line" />
    </>
  );
};
