import React from "react";
import { Standings } from "../../components/Standings/Standings";

import "./standingspage.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * The StandingsPage is a webpage that visitors can access to get an extended view of the current
 * league standings. Like all other webpages, it uses a Header, NavBar, SideVar, and Footer for
 * the main design of the page. It also implements the Standings component as the primary element
 * used in the main part of the webpage. It uses the stylings from webpage.css and scorespage.css
 * to style its content.
 */

// The UI of the StandingsPage
export const StandingsPage = () => (
  <div className="standingspage">
    <main>
      <div className="standingspage--body">
        <h1>2023 CMHL Standings</h1>
        <Standings primary />
      </div>
    </main>
  </div>
);

export default StandingsPage;
