import React from "react";
import PropTypes from "prop-types";
import { Logo } from "../Logo/Logo";

import "./schedule.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This Schedule component is a table that is styled in a way to offer website visitors an easy
 * to read overview of the league's scores and schedule. It will be used on the ScoresPage of the
 * website as the primary element displayed on the page and on the SideBar component in a 'lite'
 * format to quickly show visitors the upcoming schedule. The component takes in a {header} and
 * a {body} which contains the table data, its {format} to determine which table is to be rendered
 * and a {colour} that changes the colour of the table.
 */

// The UI for a Schedule component
export const Schedule = ({ header, body, colour }) => {
  const getTeamLogoFileName = (teamName) => {
    const modifiedTeamName = teamName.replace(/\s/g, "").toLowerCase();
    return modifiedTeamName + "-transparent";
  };

  return (
    <table className={["schedule", `schedule--${colour}`].join(" ")}>
      <thead className={`schedule--header`}>
        <tr>
          <th className="schedule--rank-cell">{header[0]}</th>
          <th className="schedule--teamheader-cell">{header[1]}</th>
          {header.slice(2).map((headerData, index) => (
            <th key={index}>{headerData} </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {header.map((headerData, colIndex) => (
              <td key={colIndex}>
                {headerData === "Home" || headerData === "Away" ? (
                  <div className="schedule--team-cell">
                    {row[headerData] !== "--" && (
                      <>
                        <Logo
                          name={getTeamLogoFileName(row[headerData])}
                          size={"tiny"}
                        />
                        {row[headerData]}
                      </>
                    )}
                    {row[headerData] === "--" && "--"}
                  </div>
                ) : (
                  <div className="schedule--center">{row[headerData]}</div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// Props for the schedule
Schedule.propTypes = {
  // What is the header data of the table?
  header: PropTypes.arrayOf(PropTypes.object).isRequired,

  // What is the body data of the table?
  body: PropTypes.arrayOf(PropTypes.object).isRequired,

  // What is the colour scheme of the table?
  colour: PropTypes.oneOf(["black", "red"]),
};

// Default props of the Schedule component
Schedule.defaultProps = {
  header: [
    "Week",
    "Game",
    "Date",
    "Home",
    "Away",
    "Time",
    "Rink",
    "HomeScore",
    "AwayScore",
  ],
  body: [
    {
      Week: 1,
      Game: 1,
      Date: "Friday, September 29, 2023",
      Home: "Rockies",
      Away: "Axemen",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "1",
      AwayScore: "9",
    },
    {
      Week: 1,
      Game: 2,
      Date: "Friday, September 29, 2023",
      Home: "Gulls",
      Away: "Whiskey Dekes",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "4",
      AwayScore: "3",
    },
    {
      Week: 2,
      Game: 3,
      Date: "Friday, October 6, 2023",
      Home: "Axemen",
      Away: "Gulls",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "4",
      AwayScore: "3",
    },
    {
      Week: 2,
      Game: 4,
      Date: "Friday, October 6, 2023",
      Home: "Whiskey Dekes",
      Away: "Rockies",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "5",
      AwayScore: "0",
    },
    {
      Week: 3,
      Game: 5,
      Date: "Friday, October 13, 2023",
      Home: "Gulls",
      Away: "Rockies",
      Time: "9:45pm",
      Rink: 3,
      HomeScore: "3",
      AwayScore: "2",
    },
    {
      Week: 3,
      Game: 6,
      Date: "Friday, October 13, 2023",
      Home: "Axemen",
      Away: "Whiskey Dekes",
      Time: "10:00pm",
      Rink: 4,
      HomeScore: "7",
      AwayScore: "5",
    },
    {
      Week: 4,
      Game: 7,
      Date: "Friday, October 20, 2023",
      Home: "Axemen",
      Away: "Rockies",
      Time: "9:45pm",
      Rink: 3,
      HomeScore: "4",
      AwayScore: "3",
    },
    {
      Week: 4,
      Game: 8,
      Date: "Friday, October 20, 2023",
      Home: "Whiskey Dekes",
      Away: "Gulls",
      Time: "10:00pm",
      Rink: 4,
      HomeScore: "3",
      AwayScore: "3",
    },
    {
      Week: 5,
      Game: 9,
      Date: "Friday, October 27, 2023",
      Home: "Rockies",
      Away: "Gulls",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "5",
    },
    {
      Week: 5,
      Game: 10,
      Date: "Friday, October 27, 2023",
      Home: "Whiskey Dekes",
      Away: "Axemen",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "6",
    },
    {
      Week: 6,
      Game: 11,
      Date: "Friday, November 3, 2023",
      Home: "Axemen",
      Away: "Rockies",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "5",
      AwayScore: "3",
    },
    {
      Week: 6,
      Game: 12,
      Date: "Friday, November 3, 2023",
      Home: "Gulls",
      Away: "Whiskey Dekes",
      Time: "9:30pm",
      Rink: 1,
      HomeScore: "4",
      AwayScore: "2",
    },
    {
      Week: 7,
      Game: 13,
      Date: "Friday, November 10, 2023",
      Home: "Axemen",
      Away: "Whiskey Dekes",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "6",
      AwayScore: "2",
    },
    {
      Week: 7,
      Game: 14,
      Date: "Friday, November 10, 2023",
      Home: "Gulls",
      Away: "Rockies",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "7",
      AwayScore: "5",
    },
    {
      Week: 8,
      Game: 15,
      Date: "Friday, November 17, 2023",
      Home: "Whiskey Dekes",
      Away: "Gulls",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "2",
    },
    {
      Week: 8,
      Game: 16,
      Date: "Friday, November 17, 2023",
      Home: "Rockies",
      Away: "Axemen",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "6",
    },
    {
      Week: 9,
      Game: 17,
      Date: "Friday, November 24, 2023",
      Home: "Whiskey Dekes",
      Away: "Rockies",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "6",
    },
    {
      Week: 9,
      Game: 18,
      Date: "Friday, November 24, 2023",
      Home: "Axemen",
      Away: "Gulls",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "6",
      AwayScore: "1",
    },
    {
      Week: 10,
      Game: 19,
      Date: "Friday, December 1, 2023",
      Home: "Axemen",
      Away: "Whiskey Dekes",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "5",
      AwayScore: "3",
    },
    {
      Week: 10,
      Game: 20,
      Date: "Friday, December 1, 2023",
      Home: "Gulls",
      Away: "Rockies",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "6",
      AwayScore: "9",
    },
    {
      Week: 11,
      Game: 21,
      Date: "Friday, December 8, 2023",
      Home: "Rockies",
      Away: "Whiskey Dekes",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "6",
      AwayScore: "5",
    },
    {
      Week: 11,
      Game: 22,
      Date: "Friday, December 8, 2023",
      Home: "Gulls",
      Away: "Axemen",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "4",
      AwayScore: "5",
    },
    {
      Week: 12,
      Game: 23,
      Date: "Friday, December 15, 2023",
      Home: "Gulls",
      Away: "Rockies",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "1",
      AwayScore: "4",
    },
    {
      Week: 12,
      Game: 24,
      Date: "Friday, December 15, 2023",
      Home: "Axemen",
      Away: "Whiskey Dekes",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "2",
    },
    {
      Week: 13,
      Game: 25,
      Date: "Friday, December 29, 2023",
      Home: "Rockies",
      Away: "Axemen",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "3",
    },
    {
      Week: 13,
      Game: 26,
      Date: "Friday, December 29, 2023",
      Home: "Gulls",
      Away: "Whiskey Dekes",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "5",
      AwayScore: "8",
    },
    {
      Week: 14,
      Game: 27,
      Date: "Friday, January 5, 2024",
      Home: "Axemen",
      Away: "Gulls",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "10",
      AwayScore: "3",
    },
    {
      Week: 14,
      Game: 28,
      Date: "Friday, January 5, 2024",
      Home: "Whiskey Dekes",
      Away: "Rockies",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "2",
      AwayScore: "3",
    },
    {
      Week: 15,
      Game: 29,
      Date: "Friday, January 19, 2024",
      Home: "Whiskey Dekes",
      Away: "Gulls",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "1",
    },
    {
      Week: 15,
      Game: 30,
      Date: "Friday, January 19, 2024",
      Home: "Rockies",
      Away: "Axemen",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "6",
    },
    {
      Week: 16,
      Game: 31,
      Date: "Friday, January 26, 2024",
      Home: "Whiskey Dekes",
      Away: "Axemen",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "6",
      AwayScore: "6",
    },
    {
      Week: 16,
      Game: 32,
      Date: "Friday, January 26, 2024",
      Home: "Rockies",
      Away: "Gulls",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "6",
    },
    {
      Week: 17,
      Game: 33,
      Date: "Friday, February 2, 2024",
      Home: "Gulls",
      Away: "Axemen",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "7",
      AwayScore: "2",
    },
    {
      Week: 17,
      Game: 34,
      Date: "Friday, February 2, 2024",
      Home: "Rockies",
      Away: "Whiskey Dekes",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "5",
      AwayScore: "5",
    },
    {
      Week: 18,
      Game: 35,
      Date: "Friday, February 9, 2024",
      Home: "Gulls",
      Away: "Whiskey Dekes",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "6",
      AwayScore: "2",
    },
    {
      Week: 18,
      Game: 36,
      Date: "Friday, February 9, 2024",
      Home: "Rockies",
      Away: "Axemen",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "3",
      AwayScore: "3",
    },
    {
      Week: 19,
      Game: 37,
      Date: "Friday, February 16, 2024",
      Home: "Whiskey Dekes",
      Away: "Rockies",
      Time: "8:15pm",
      Rink: 2,
      HomeScore: "6",
      AwayScore: "1",
    },
    {
      Week: 19,
      Game: 38,
      Date: "Friday, February 16, 2024",
      Home: "Axemen",
      Away: "Gulls",
      Time: "9:15pm",
      Rink: 2,
      HomeScore: "2",
      AwayScore: "5",
    },
    {
      Week: 20,
      Game: 39,
      Date: "Friday, February 23, 2024",
      Home: "Whiskey Dekes",
      Away: "Axemen",
      Time: "9:30pm",
      Rink: 1,
      HomeScore: "2",
      AwayScore: "2",
    },
    {
      Week: 20,
      Game: 40,
      Date: "Friday, February 23, 2024",
      Home: "Rockies",
      Away: "Gulls",
      Time: "10:15pm",
      Rink: 2,
      HomeScore: "4",
      AwayScore: "4",
    },
    {
      Week: 21,
      Game: 41,
      Date: "Friday, March 1, 2024",
      Home: "Gulls",
      Away: "Whiskey Dekes",
      Time: "9:30pm",
      Rink: 1,
      HomeScore: "7",
      AwayScore: "4",
    },
    {
      Week: 21,
      Game: 42,
      Date: "Friday, March 1, 2024",
      Home: "Rockies",
      Away: "Axemen",
      Time: "10:15pm",
      Rink: 2,
      HomeScore: "6",
      AwayScore: "3",
    },
    {
      Week: 22,
      Game: 43,
      Date: "Friday, March 8, 2024",
      Home: "Whiskey Dekes",
      Away: "Rockies",
      Time: "9:30pm",
      Rink: 1,
      HomeScore: "1",
      AwayScore: "3",
    },
    {
      Week: 22,
      Game: 44,
      Date: "Friday, March 8, 2024",
      Home: "Axemen",
      Away: "Gulls",
      Time: "10:30pm",
      Rink: 1,
      HomeScore: "8",
      AwayScore: "4",
    },
    {
      Week: "SF",
      Game: 45,
      Date: "Friday, March 15, 2024",
      Home: "Axemen",
      Away: "Whiskey Dekes",
      Time: "8:30pm",
      Rink: 1,
      HomeScore: "7",
      AwayScore: "2",
    },
    {
      Week: "SF",
      Game: 46,
      Date: "Friday, March 15, 2024",
      Home: "Gulls",
      Away: "Rockies",
      Time: "9:30pm",
      Rink: 1,
      HomeScore: "5",
      AwayScore: "3",
    },
    {
      Week: "Final",
      Game: 47,
      Date: "Friday, March 22, 2024",
      Home: "Axemen",
      Away: "Gulls",
      Time: "9:30pm",
      Rink: 2,
      HomeScore: "4",
      AwayScore: "5",
    },
  ],
  colour: "black",
};
