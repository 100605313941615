import React from "react";
import { Logo } from "../Logo/Logo";

import "./header.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This Header component is displayed on the top of every web page. It contains the league logo and
 * the title of the league.
 */

// The UI for a Header component
export const Header = () => (
  <>
    <header className="header">
      <Logo padding={30} />
      <h1>Canucks Men's Hockey League</h1>
    </header>
  </>
);
