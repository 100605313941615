import React from "react";

import "./footer.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This Footer component is used on the bottom of every page. Currently, it only contains the date
 * in which the league was established.
 *
 * In future updates, I intend on having the league logo and team logos, as well as contact info
 * to finish the design of the component.
 */

// The UI for a Footer component
export const Footer = () => (
  <footer className="footer">
    <div className="footer--red-line" />
    <div className="footer--white-line" />
    <div>
      <p style={{ fontSize: 14 }}>est. 2023</p>
    </div>
  </footer>
);
