import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Header } from "./components/Header/Header";
import { NavBar } from "./components/NavBar/NavBar";
import { SideBar } from "./components/SideBar/SideBar";
import { Footer } from "./components/Footer/Footer";

import { HomePage } from "./pages/HomePage/HomePage";
import { InfoPage } from "./pages/InfoPage/InfoPage";
import { RulesPage } from "./pages/RulesPage/RulesPage";
import { ScoresPage } from "./pages/ScoresPage/ScoresPage";
import { StandingsPage } from "./pages/StandingsPage/StandingsPage";

import "../src/pages/webpage.css";

ReactDOM.render(
  <Router>
    <div className="webpage">
      <Header />
      <NavBar />
      <main>
        <SideBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/info" element={<InfoPage />} />
          <Route path="/rules" element={<RulesPage />} />
          <Route path="/scores" element={<ScoresPage />} />
          <Route path="/standings" element={<StandingsPage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  </Router>,
  document.getElementById("root")
);
