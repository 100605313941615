import React from "react";
import { Standings } from "../Standings/Standings";
import { Matchup } from "../Matchup/Matchup";

import "./sidebar.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This SideBar component is the first element on the left side of the each page immediately following
 * the Header and NavBar. It gives visitors to the web site a condensed glance at the most important
 * information - the upcoming schedule, and the league standings. The SideBar implements the Table
 * component displaying the small version of the current standings.
 */

// The UI for a SideBar component.
export const SideBar = () => (
  <div className="sidebar">
    <h3>Standings</h3>
    <Standings
      header={["RK", "Team", "W", "L", "T", "P"]}
      body={[
        { Rank: 1, Team: "Axemen", W: 15, L: 3, T: 4, P: 34 },
        { Rank: 2, Team: "Gulls", W: 10, L: 10, T: 2, P: 22 },
        { Rank: 3, Team: "Rockies", W: 7, L: 11, T: 4, P: 18 },
        { Rank: 4, Team: "Whiskey Dekes", W: 5, L: 13, T: 4, P: 14 },
      ]}
      colour={"grey"}
    />
  </div>
);
