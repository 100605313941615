import React from "react";
import PropTypes from "prop-types";

import "./logo.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This Logo component is widely used across the website including the Header and the NavBar. Using
 * {name}, it retrieves an image from the 'assets' folder and renders it with a given {size}. The user
 * has the option of adding horizontal {padding} as well as an {overlay} with a given {overlayText}.
 */

// The UI for a Logo component
export const Logo = ({ name, size, padding, overlay, overlayText }) => {
  const image = require(`../../assets/logos/${name}.png`);
  const horizontalPadding = {
    paddingLeft: `${padding}px`,
    paddingRight: `${padding}px`,
  };

  return (
    <div style={horizontalPadding}>
      <img src={image} className={`logo--${size}`} alt="" />
      {overlay && (
        <div className={["overlay", `overlay--${size}`].join(" ")}>
          {overlayText}
        </div>
      )}
    </div>
  );
};

// Props for the logo
Logo.propTypes = {
  // What is the name of the asset to be retrieved?
  name: PropTypes.string.isRequired,

  // What is the size of the Logo?
  size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),

  // What is the horizontal padding the user wishes to add to the logo?
  padding: PropTypes.number,

  // Does the user wish to have an overlay?
  overlay: PropTypes.bool,

  // What is the text for the overlay?
  overlayText: PropTypes.string,
};

// Default props for a Logo
Logo.defaultProps = {
  name: "logo-transparent-white",
  size: "large",
  padding: 0,
  overlay: false,
  overlayText: "Team Name",
};
