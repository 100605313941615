import React from "react";
import { NewsFeed } from "../../components/NewsFeed/NewsFeed";

import "./homepage.css";
import "../webpage.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * The HomePage is the initial landing page for a visitor to the website. Like all other pages, it
 * incorporates a Header, SideBar, NavBar, and Footer to build out its page. The page displays
 * news, with newest articles added at the top of the main section and oldest ones at the bottom. It
 * uses the stylings from webpage.css and homepage.css to style its content.
 */

// The UI of the HomePage
export const HomePage = () => (
  <div className="homepage">
    <NewsFeed
      title="Gulls Win Inaugural CMHL Season"
      date="Thursday, July 25, 2024"
      image="gulls-win-2024.jpg"
      imageSize="large"
      textBody={`A back and forth affair between the Gulls and Axemen played to an exciting title match. The Axemen quickly jumped out to a commanding lead before the Gulls dug deep and mounted a comeback to push the game into overtime. After successfully stealing the puck behind the net from an Axemen defender, Nick Bosco found Cesare Caldaroni who fired home the championship clinching goal. Congratulations to the Gulls on winning the inaugural league title!\n\nThank you to all players for making our first year a success and we look forward to next season.\n\nCheck back in the near future for details.`}
    />
    <NewsFeed
      title="Welcome to the CMHL"
      date="Tuesday, October 3, 2023"
      image="logos/logo-transparent-black.png"
      textBody={`Welcome to the official website of the Canucks Men's Hockey League (CMHL) based out of the Gale Centre Arena in Niagara Falls, Ontario. We are excited to drop the puck on our inaugural season with our first four teams - Axemen, Gulls, Rockies, and Whiskey Dekes. \n\nAll players are expected to register, know their team's schedule, and familiarize themselves with league rules and information. \n\nInterested in registering a team for next season? Please reach out to us at cmhlniagara@gmail.com`}
    />
  </div>
);
