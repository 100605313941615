import React from "react";
import PropTypes from "prop-types";
import { Logo } from "../Logo/Logo";

import "./matchup.css";

export const Matchup = ({ primary, home, away, date, time, rink }) => {
  const mode = primary ? "matchup--primary" : "matchup--secondary";

  const getTeamLogoFileName = (teamName) => {
    const modifiedTeamName = teamName.replace(/\s/g, "").toLowerCase();
    return modifiedTeamName + "-transparent";
  };

  return (
    <div className={["matchup", mode].join(" ")}>
      <Logo
        overlay
        overlayText={away}
        size={"small"}
        name={getTeamLogoFileName(away)}
      />
      <p>
        {date}
        <br />
        {time}
        <br />
        {`RINK ${rink}`}
        <br />@
      </p>
      <Logo
        overlay
        overlayText={home}
        size={"small"}
        name={getTeamLogoFileName(home)}
      />
    </div>
  );
};

Matchup.propTypes = {
  primary: PropTypes.bool,
  home: PropTypes.string.isRequired,
  away: PropTypes.string.isRequired,
  date: PropTypes.string,
  time: PropTypes.string,
  rink: PropTypes.string,
};

Matchup.defaultprops = {
  primary: false,
  home: "rockies",
  away: "axemen",
  date: "Friday September 29, 2023",
  time: "8:15 PM",
  rink: "2",
};

export default Matchup;
